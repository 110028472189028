<template>
    <div>
        <Disclosure as="div" v-for="cookie in cookies" :key="cookie.title" class="pt-6" v-slot="{ open }">
            <dt class="text">
                <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-400">
                    <span class="font-medium text-gray-900">{{ cookie.title }}</span>
                    <span class="ml-6 flex h-7 items-center">
                        <span class="text-xs" :class="cookie.key === 'functional' ? 'inline' : 'hidden'">
                            Notwendig
                        </span>
                        <Switch
                            v-if="cookie.switch"
                            v-model="contents[cookie.key]"
                            @input="$emit('update:consent', contents)"
                            :class="[
                                contents[cookie.key] ? 'bg-blue-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                            ]"
                        >
                            <span class="sr-only">Use setting</span>
                            <span
                                :class="[
                                    contents[cookie.key] ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                ]"
                            >
                                <span
                                    :class="[
                                        contents[cookie.key]
                                            ? 'opacity-0 duration-100 ease-out'
                                            : 'opacity-100 duration-200 ease-in',
                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                                    ]"
                                    aria-hidden="true"
                                >
                                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                        <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    :class="[
                                        contents[cookie.key]
                                            ? 'opacity-100 duration-200 ease-in'
                                            : 'opacity-0 duration-100 ease-out',
                                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                                    ]"
                                    aria-hidden="true"
                                >
                                    <svg class="h-3 w-3 text-blue-600" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                        />
                                    </svg>
                                </span>
                            </span>
                        </Switch>
                        <ChevronDownIcon
                            :class="[open ? '-rotate-180' : 'rotate-0', 'ml-2 h-6 w-6 transform']"
                            aria-hidden="true"
                        />
                    </span>
                </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
                <p class="text-sm text-gray-500">
                    {{ cookie.text }}
                </p>
            </DisclosurePanel>
        </Disclosure>
    </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Switch } from '@headlessui/vue'

import { ChevronDownIcon } from '@heroicons/vue/24/solid'

defineEmits(['update:consent'])

const props = defineProps({
    consent: {
        type: Object,
        default: () => ({ functional: true, statistics: false, marketing: false }),
    },
})

const contents = props.consent

const cookies = [
    {
        key: 'functional',
        switch: false,
        title: 'Funktional',
        text: 'Die technische Speicherung oder der Zugang ist unbedingt erforderlich für den rechtmäßigen Zweck, die Nutzung eines bestimmten Dienstes zu ermöglichen, der vom Teilnehmer oder Nutzer ausdrücklich gewünscht wird, oder für den alleinigen Zweck, die Übertragung einer Nachricht über ein elektronisches Kommunikationsnetz durchzuführen.',
    },
    {
        key: 'statistics',
        switch: true,
        title: 'Statistiken',
        text: 'Die technische Speicherung oder der Zugriff, der ausschließlich zu statistischen Zwecken erfolgt.',
    },
    {
        key: 'marketing',
        switch: true,
        title: 'Marketing',
        text: 'Die technische Speicherung oder der Zugriff ist erforderlich, um Nutzerprofile zu erstellen, um Werbung zu versenden oder um den Nutzer auf einer Website oder über mehrere Websites hinweg zu ähnlichen Marketingzwecken zu verfolgen.',
    },
]
</script>
