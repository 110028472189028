<template>
    <div v-show="showBanner" class="fixed inset-0 flex h-screen items-center justify-center bg-white/40 px-6 pb-6">
        <div>
            <div class="pointer-events-auto mt-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
                <p class="text-sm leading-6 text-gray-900">
                    Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene
                    Daten von dir (z.B. IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren, Medien von
                    Drittanbietern einzubinden oder Zugriffe auf unsere Website zu analysieren. Weitere Informationen
                    findest du in unseren
                    <Link href="/datenschutz" class="font-semibold text-lime-600">Datenschutzbestimmungen</Link>.
                </p>
                <CookieSettings v-if="settingsOpen" :consent="cookie_consent" />
                <div class="mt-4 flex items-center gap-x-5">
                    <button
                        type="button"
                        @click="acceptAll"
                        class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                        Alle akzeptieren
                    </button>
                    <button type="button" @click="handleSettings" class="text-sm font-semibold leading-6 text-gray-900">
                        {{ settingsOpen ? 'Speichern' : 'Einstellungen' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useGtm } from '@gtm-support/vue-gtm'
import CookieSettings from '@/Components/CookieSettings.vue'

const gtm = useGtm()

const showBanner = ref(false)
const settingsOpen = ref(false)

const $cookies = inject('$cookies')

const cookie_consent = reactive({
    functional: false,
    statistics: false,
    marketing: false,
})

function acceptAll() {
    saveSettings(true, true)
}

function handleSettings() {
    if (settingsOpen.value) {
        saveSettings(cookie_consent['statistics'], cookie_consent['marketing'])
    } else {
        settingsOpen.value = true
    }
}

function saveSettings(statistics = false, marketing = false) {
    $cookies.set('cookie_consent_functional', true, 60 * 60 * 24 * 30)
    $cookies.set('cookie_consent_statistics', statistics, 60 * 60 * 24 * 30)
    $cookies.set('cookie_consent_marketing', marketing, 60 * 60 * 24 * 30)

    function gtag() {
        window.dataLayer.push(arguments)
    }
    gtag('consent', 'update', {
        ad_user_data: marketing ? 'granted' : 'denied',
        ad_personalization: marketing ? 'granted' : 'denied',
        ad_storage: marketing ? 'granted' : 'denied',
        analytics_storage: statistics ? 'granted' : 'denied',
        functionality_storage: 'granted',
    })

    gtm.enable(marketing)

    showBanner.value = false
}

onMounted(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
        window.dataLayer.push(arguments)
    }

    if (!$cookies.get('cookie_consent_functional')) {
        showBanner.value = true

        gtag('consent', 'default', {
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            analytics_storage: 'denied',
        })
    } else {
        let marketing = $cookies.get('cookie_consent_marketing') === 'true'
        let statistics = $cookies.get('cookie_consent_statistics') === 'true'

        gtag('consent', 'update', {
            ad_user_data: marketing ? 'granted' : 'denied',
            ad_personalization: marketing ? 'granted' : 'denied',
            ad_storage: marketing ? 'granted' : 'denied',
            analytics_storage: statistics ? 'granted' : 'denied',
            functionality_storage: 'granted',
        })

        gtm.enable(marketing)
    }
})
</script>
